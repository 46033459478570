<template>
  <div>
    <catalog-create-tabs class="mb-1 w-100" />
    <catalog-form
      v-if="catalog.id"
      ref="catalog-form"
      :is-update-form="true"
      :service="service"
      @refetchData="refetchData"
    />
    <skeleton v-else />
  </div>
</template>

<script>
import mainCatalogMenuItems from '@/views/components/sub-menu/main-sub-menu/home-catalog'
import CatalogForm from '@/views/main/catalog/components/catalogForm.vue'
import {
  CATALOG_TYPE_COMPONENT,
  CATALOG_TYPE_PRODUCT,
  CATALOG_TYPE_CONTAINER,
} from '@/views/main/catalog/catalogType'
import NonStockService from '@/views/main/catalog/service/NonStockService'
import StockProductService from '@/views/main/catalog/service/StockProductService'
import StockContainerService from '@/views/main/catalog/service/StockContainerService'
import StockComponentService from '@/views/main/catalog/service/StockComponentService'
import CatalogCreateTabs from '@/views/main/catalog/components/CatalogTabs/CatalogTabs.vue'
import Skeleton from './skeletons/skeleton.vue'

export default {
  name: 'General',
  components: { CatalogCreateTabs, CatalogForm, Skeleton },
  data() {
    return {
      getIsProductNeedsIndividualAsset: false,
    }
  },
  computed: {
    catalog() {
      return this.$store.state[this.MODULE_NAME].catalogForm
    },
    getCatalog() {
      return this.$store.state[this.MODULE_NAME].catalog
    },
    portalTopTitle() {
      const { sku, name } = this.getCatalog
      return `${sku} - ${name}`
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['catalog-form']) this.$refs['catalog-form'].showConfirmationModal(next)
  },
  async created() {
    if (this.getCatalog.type) {
      switch (this.getCatalog.type.id) {
        case CATALOG_TYPE_PRODUCT:
          this.service = new StockProductService()
          break
        case CATALOG_TYPE_CONTAINER:
          this.service = new StockContainerService()
          break
        case CATALOG_TYPE_COMPONENT:
          this.service = new StockComponentService()
          break
        default:
          this.service = new NonStockService()
      }
    }
    this.$emit('updateMenu', 'home-catalog-general-info')
    this.refetchData()
  },
  methods: {
    async refetchData() {
      this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params?.id)
        .then(res => {
          const { data } = res.data

          this.getIsProductNeedsIndividualAsset = data.detail.needs_individual_asset_id

          const catalog = {
            ...this.service.beforeMappingFields(data),
            ...this.service.beforeMappingDetailFields(data.detail),
          }

          this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_FORM`, catalog)
          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_FORM_CLONE`, catalog)
        }).finally(() => {
          // if (!this.getIsProductNeedsIndividualAsset) {
          this.$store.dispatch(`${this.MODULE_NAME}/generateBarcode`, {})
            .then(res => {
              this.$store.commit(`${this.MODULE_NAME}/SET_CATALOG_PRODUCT_BARCODE`, res.data.data.html)
            })
          // }
        })
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const { menuItems } = mainCatalogMenuItems('home-catalog-create', {}, true)
    const service = null

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      menuItems,
      service,
    }
  },
}
</script>
<style>
.form-control:disabled {
  -webkit-text-fill-color: #646464 !important;
}
.form-control:disabled::placeholder {
  -webkit-text-fill-color: currentcolor !important;
}
</style>
