// eslint-disable-next-line import/prefer-default-export
export default function mainCatalogMenuItems(active, params = {}, create = false) {
  const menuItems = [
    {
      name: create ? 'home-catalog-create' : 'home-catalog-general-info',
      layout: 'General',
    },
    {
      name: 'home-catalog-rates',
      layout: 'Rates',
    },
    {
      name: 'home-catalog-phy-properties',
      layout: 'Physical Properties',
    },
    {
      name: 'home-catalog-components',
      layout: 'Components',
    },
    {
      name: 'home-catalog-attachments',
      layout: 'Attachments',
    },
    {
      name: 'home-catalog-procurement',
      layout: 'Procurement',
    },
    {
      name: 'home-catalog-substitutes',
      layout: 'Substitutes',
    },
    {
      name: 'home-catalog-audit',
      layout: 'Audit',
    },
    {
      name: 'home-catalog-reports',
      layout: 'Reports',
    },
  ]

  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
    // eslint-disable-next-line no-param-reassign
    item.disabled = (create && item.name !== active)
  })
  return {
    menuItems,
  }
}
